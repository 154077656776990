import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";  
import { useState, useEffect } from 'react';

const Calc = () => {
    const { t, i18n } = useTranslation();
    const { query } = useParams();

    var dataCalc = {inspectionLevel:"i", qualLevelCritical:"0", qualLevelMajor:"2.5", qualLevelMinor:"4.0", foundCrit:0, foundMaj:0, foundMin:0};
    try{
        if(query !== undefined){
            const dataJson = decodeURIComponent(atob(query));
            dataCalc = JSON.parse(dataJson);
        }
    }catch{
    }

    const [data, setData] = useState(dataCalc);
    const [resultAQL, setResultAQL] = useState({});

    useEffect(()=>{
        const aql = {}
//console.log(data);
        let CODE_LETTER = '';
        if(data?.batchSize!==undefined && data?.inspectionLevel!==undefined){
         for (const [key, value] of Object.entries(dataTable1)) {
            if(data.batchSize>=value.min && data.batchSize<=value.max){
//console.log(value[data.inspectionLevel]);
                CODE_LETTER = value[data.inspectionLevel];
//CRITICAL
                if(dataTable2[CODE_LETTER][data.qualLevelCritical]?.ref !== undefined){
                    const CRITICAL_LETTER = dataTable2[CODE_LETTER][data.qualLevelCritical].ref;
                    aql.crit = {batchSize:dataTable2[CRITICAL_LETTER].batch_size,
                                Ac:dataTable2[CRITICAL_LETTER][data.qualLevelCritical]?.Ac,
                                Re:dataTable2[CRITICAL_LETTER][data.qualLevelCritical]?.Re};
                }else{                   
                    aql.crit = {batchSize:dataTable2[CODE_LETTER].batch_size,
                                Ac:dataTable2[CODE_LETTER][data.qualLevelCritical]?.Ac,
                                Re:dataTable2[CODE_LETTER][data.qualLevelCritical]?.Re};
                }

                if(aql.crit.batchSize > data.batchSize){
                    aql.crit.batchSize = data.batchSize;
                }

                if(data.qualLevelCritical==="0"){
                    aql.crit.batchSize = "0";
                }
//MAJOR
//console.log(dataTable2[CODE_LETTER]);
//console.log(data);
                if(dataTable2[CODE_LETTER][data.qualLevelMajor]?.ref !== undefined){
                    const MAJOR_LETTER = dataTable2[CODE_LETTER][data.qualLevelMajor].ref;
                    aql.major = {batchSize:dataTable2[MAJOR_LETTER].batch_size, 
                                 Ac:dataTable2[MAJOR_LETTER][data.qualLevelMajor]?.Ac,
                                 Re:dataTable2[MAJOR_LETTER][data.qualLevelMajor]?.Re};
                }else{
                    aql.major = {batchSize:dataTable2[CODE_LETTER].batch_size,
                                 Ac:dataTable2[CODE_LETTER][data.qualLevelMajor]?.Ac,
                                 Re:dataTable2[CODE_LETTER][data.qualLevelMajor]?.Re};
                }

                if(aql.major.batchSize > data.batchSize){
                    aql.major.batchSize = data.batchSize;
                }             

                if(data.qualLevelMajor==="0"){
                    aql.major.batchSize = "0";
                }   
//MINOR
//console.log(dataTable2[CODE_LETTER][data.qualLevelMinor]);
                if(dataTable2[CODE_LETTER][data.qualLevelMinor]?.ref !== undefined){
                    const MINOR_LETTER = dataTable2[CODE_LETTER][data.qualLevelMinor].ref;
                    aql.minor = {batchSize:dataTable2[MINOR_LETTER].batch_size,
                                 Ac:dataTable2[MINOR_LETTER][data.qualLevelMinor]?.Ac,
                                 Re:dataTable2[MINOR_LETTER][data.qualLevelMinor]?.Re};
                }else{
                    aql.minor = {batchSize:dataTable2[CODE_LETTER].batch_size,
                                 Ac:dataTable2[CODE_LETTER][data.qualLevelMinor]?.Ac,
                                 Re:dataTable2[CODE_LETTER][data.qualLevelMinor]?.Re};
                }

                if(aql.minor.batchSize > data.batchSize){
                    aql.minor.batchSize = data.batchSize;
                }        
                if(data.qualLevelMinor==="0"){
                    aql.minor.batchSize = "0";
                }  
            }
         }
        }



      const jsonStringData = encodeURIComponent(JSON.stringify(data));
      const base64data = btoa(jsonStringData);
      const url = new URL(window.location);
      const parts = url.pathname.split("/");
      var new_part_str = "";
      if(parts.length === 3 || parts.length === 4){        
        for(let i=0;i<3;i++){
            new_part_str += parts[i]+"/";
        }
        new_part_str += base64data;
      }
      window.history.pushState({}, '', new_part_str);
      setResultAQL(aql);
    }, [data]);


    useEffect(()=>{
//console.log(resultAQL);
    },[resultAQL]);

    return(
        <>
         <div style={{margin:"15px", paddingBottom:"20px"}}>
            <table style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <td style={{width:"50%"}}>
                         <div style={{width:"100%", display:"block", margin:"auto"}}>
                             <label style={{display:"block"}}>{t('description.batchsize')}</label>
                               <input style={{width:"80%", display:"block"}} type="text" value={(data?.batchSize===undefined?"":data?.batchSize)} onChange={(e)=>{setData((prev)=>({...prev, batchSize:e.target.value}))}}/>
                         </div>
                        </td>
                        <td style={{width:"50%"}}>
                        <div style={{width:"100%",  display:"block", margin:"auto"}}>
                             <label style={{display:"block"}}>{t('description.levelInspection')}</label>
                          <select defaultValue={(data?.inspectionLevel===undefined?"i":data?.inspectionLevel)} onChange={(e)=>{setData((prev)=>({...prev, inspectionLevel:e.target.value}))}}>
                            <optgroup label={t('description.levelGeneralInspection')}>
                                <option value="i">I</option>
                                <option value="ii">II</option>
                                <option value="iii">III</option>
                            </optgroup>
                            <optgroup label={t('description.levelSpecialInspection')}>
                                <option value="s1">S1</option>
                                <option value="s2">S2</option>
                                <option value="s3">S3</option>
                                <option value="s4">S4</option>
                            </optgroup>
                         </select>
                         </div>
                        </td>
                    </tr>
                </tbody>
            </table>



            <div style={{width:"100%", backgroundColor:"#fbccbb", display:"block", margin:"auto"}}>

            <table style={{width:"100%"}}>
                <thead>
                <tr style={styleTableTR}>
                    <td>
                    {t('description.levelAQL')}
                    </td>
                    <td>
                    {t('description.lotsize')} 
                    </td>
                    <td>
                    {t('description.acceptable')} 
                    </td>
                    <td>
                    {t('description.discovered')} 
                    </td>
                    <td>
                    {t('description.result')}  
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr style={{...styleTableTR, backgroundColor:"#eb4034"}}>
                    <td>
                        <span style={{display:"block"}}>
                        {t('description.critical')}
                        </span>
                        <select style={{...styleTableTR, display:"block"}}
                          defaultValue={(data?.qualLevelCritical===undefined?"0":data?.qualLevelCritical)} onChange={(e)=>{setData((prev)=>({...prev, qualLevelCritical:e.target.value}))}}>
                                <option value="0">{t('description.notfilledin')}</option>
                                <option value="0.065">0.065</option>
                                <option value="0.1">0.1</option>
                                <option value="0.15">0.15</option>
                                <option value="0.25">0.25</option>
                                <option value="0.4">0.4</option>
                                <option value="0.65">0.65</option>
                                <option value="1.0">1.0</option>
                                <option value="1.5">1.5</option>
                                <option value="2.5">2.5</option>
                                <option value="4.0">4.0</option>
                                <option value="6.5">6.5</option>
                       </select>                        
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.crit===undefined)?"0":resultAQL.crit.batchSize}
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.crit?.Ac===undefined)?"0":resultAQL.crit.Ac}
                    </td>
                    <td style={{textAlign:"center"}}>
                        <input type='number' style={{width:"25px"}} value={(data?.foundCrit===undefined?"0":data?.foundCrit)} onChange={(e)=>{setData((prev)=>({...prev, foundCrit:e.target.value}))}}/>
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(data?.foundCrit!==undefined?(resultAQL?.crit?.Re!==undefined?(data.foundCrit>=resultAQL.crit.Re?(<span style={{color:"#8c0f06"}}><b>{t('description.failed')}</b></span>):(<span style={{color:"#12c791"}}><b>{t('description.passed')}</b></span>)):""):"")}
                    </td>
                </tr>     
                <tr style={{...styleTableTR, backgroundColor:"#eb8f34"}}>
                    <td>
                        <span style={{display:"block"}}>
                        {t('description.major')}
                        </span>
                        <select style={{...styleTableTR, display:"block"}}
                          defaultValue={(data?.qualLevelMajor===undefined?"2.5":data?.qualLevelMajor)} onChange={(e)=>{setData((prev)=>({...prev, qualLevelMajor:e.target.value}))}}>
                                <option value="0">{t('description.notfilledin')}</option>
                                <option value="0.065">0.065</option>
                                <option value="0.1">0.1</option>
                                <option value="0.15">0.15</option>
                                <option value="0.25">0.25</option>
                                <option value="0.4">0.4</option>
                                <option value="0.65">0.65</option>
                                <option value="1.0">1.0</option>
                                <option value="1.5">1.5</option>
                                <option value="2.5">2.5</option>
                                <option value="4.0">4.0</option>
                                <option value="6.5">6.5</option>
                       </select>        
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.major===undefined)?"0":resultAQL.major.batchSize}
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.major?.Ac===undefined)?"0":resultAQL.major.Ac}
                    </td>
                    <td style={{textAlign:"center"}}>
                        <input type='number' style={{width:"25px"}} value={(data?.foundMaj===undefined?"0":data?.foundMaj)} onChange={(e)=>{setData((prev)=>({...prev, foundMaj:e.target.value}))}}/>
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(data?.foundMaj!==undefined?(resultAQL?.major?.Re!==undefined?(data.foundMaj>=resultAQL.major.Re?(<span style={{color:"#8c0f06"}}><b>{t('description.failed')}</b></span>):(<span style={{color:"#12c791"}}><b>{t('description.passed')}</b></span>)):""):"")}
                    </td>
                </tr>   
                <tr style={{...styleTableTR, backgroundColor:"#ebd934"}}>
                    <td>
                        <span style={{display:"block"}}>
                        {t('description.minor')}
                        </span>
                        <select style={{...styleTableTR, display:"block"}}
                          defaultValue={(data?.qualLevelMinor===undefined?"4.0":data?.qualLevelMinor)} onChange={(e)=>{setData((prev)=>({...prev, qualLevelMinor:e.target.value}))}}>
                                <option value="0">{t('description.notfilledin')}</option>
                                <option value="0.065">0.065</option>
                                <option value="0.1">0.1</option>
                                <option value="0.15">0.15</option>
                                <option value="0.25">0.25</option>
                                <option value="0.4">0.4</option>
                                <option value="0.65">0.65</option>
                                <option value="1.0">1.0</option>
                                <option value="1.5">1.5</option>
                                <option value="2.5">2.5</option>
                                <option value="4.0">4.0</option>
                                <option value="6.5">6.5</option>
                       </select>                          
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.minor===undefined)?"0":resultAQL.minor.batchSize}
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(resultAQL?.minor?.Ac===undefined)?"0":resultAQL.minor.Ac}
                    </td>
                    <td style={{textAlign:"center"}}>
                        <input type='number' style={{width:"25px"}} value={(data?.foundMin===undefined?"0":data?.foundMin)} onChange={(e)=>{setData((prev)=>({...prev, foundMin:e.target.value}))}}/>
                    </td>
                    <td style={{...styleTableTR, textAlign:"center"}}>
                        {(data?.foundMin!==undefined?(resultAQL?.minor?.Re!==undefined?(data.foundMin>=resultAQL.minor.Re?(<span style={{color:"#8c0f06"}}><b>{t('description.failed')}</b></span>):(<span style={{color:"#12c791"}}><b>{t('description.passed')}</b></span>)):""):"")}
                    </td>
                </tr>                                                  
                </tbody>
            </table>

            </div>

            <div style={{width:"100%", backgroundColor:"#fbccbb", display:"block", margin:"auto"}}>
               
            </div>

         </div>
        </>
    )

}


const styleTableTR = {fontSize:`${(window.innerWidth>600)?"medium":"xx-small"}`}

// General Inspection Level - Уровень общей инспекции
// i, ii, iii

// Special Inspection Level - Уровень специальной инспекции
// s1, s2, s3, s4

const dataTable1 = {
    batch_2_8:           { min:2, max:8,              i:'A',ii:'A',iii:'B', s1:'A',s2:'A',s3:'A',s4:'A'},
    batch_9_15:          { min:9, max:15,             i:'A',ii:'B',iii:'C', s1:'A',s2:'A',s3:'A',s4:'A'},
    batch_16_25:         { min:16, max:25,            i:'B',ii:'C',iii:'D', s1:'A',s2:'A',s3:'B',s4:'B'},
    batch_26_50:         { min:26, max:50,            i:'C',ii:'D',iii:'E', s1:'A',s2:'B',s3:'B',s4:'C'},
    batch_51_90:         { min:51, max:90,            i:'C',ii:'E',iii:'F', s1:'B',s2:'B',s3:'C',s4:'C'},
    batch_91_150:        { min:91, max:150,           i:'D',ii:'F',iii:'G', s1:'B',s2:'B',s3:'C',s4:'D'},
    batch_151_280:       { min:151, max:280,          i:'E',ii:'G',iii:'H', s1:'B',s2:'C',s3:'D',s4:'E'},
    batch_281_500:       { min:281, max:500,          i:'F',ii:'H',iii:'J', s1:'B',s2:'C',s3:'D',s4:'E'},
    batch_501_1200:      { min:501, max:1200,         i:'G',ii:'J',iii:'K', s1:'C',s2:'C',s3:'E',s4:'F'},
    batch_1201_3200:     { min:1201, max:3200,        i:'H',ii:'K',iii:'L', s1:'C',s2:'D',s3:'E',s4:'G'},
    batch_3201_10000:    { min:3201, max:10000,       i:'J',ii:'L',iii:'M', s1:'C',s2:'D',s3:'F',s4:'G'},
    batch_10001_35000:   { min:10001, max:35000,      i:'K',ii:'M',iii:'N', s1:'C',s2:'D',s3:'F',s4:'H'},
    batch_35001_150000:  { min:35001, max:150000,     i:'L',ii:'N',iii:'P', s1:'D',s2:'E',s3:'G',s4:'J'},
    batch_150001_500000: { min:150001, max:500000,    i:'M',ii:'P',iii:'Q', s1:'D',s2:'E',s3:'G',s4:'J'},
    batch_500001_9999999:{ min:500001, max:999999999, i:'N',ii:'Q',iii:'R', s1:'D',s2:'E',s3:'H',s4:'K'}
}
// Ac - приемлемое число
// Re - число брака

const dataTable2 = {
    'A':{batch_size:2, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':0, 'Re':1, ref:'E'},'1.5':{'Ac':0, 'Re':1, ref:'D'},'2.5':{'Ac':0, 'Re':1, ref:'C'},'4.0':{'Ac':0, 'Re':1, ref:'B'},'6.5':{'Ac':0, 'Re':1},},
    'B':{batch_size:3, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':0, 'Re':1, ref:'E'},'1.5':{'Ac':0, 'Re':1, ref:'D'},'2.5':{'Ac':0, 'Re':1, ref:'C'},'4.0':{'Ac':0, 'Re':1},'6.5':{'Ac':0, 'Re':1, ref:'A'},},

    'C':{batch_size:5, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':0, 'Re':1, ref:'E'},'1.5':{'Ac':0, 'Re':1, ref:'D'},'2.5':{'Ac':0, 'Re':1},'4.0':{'Ac':0, 'Re':1, ref:'B'},'6.5':{'Ac':1, 'Re':2, ref:'D'},},
    'D':{batch_size:8, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':0, 'Re':1, ref:'E'},'1.5':{'Ac':0, 'Re':1},'2.5':{'Ac':0, 'Re':1, ref:'C'},'4.0':{'Ac':1, 'Re':2},'6.5':{'Ac':1, 'Re':2},},
    'E':{batch_size:13, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':0, 'Re':1},'1.5':{'Ac':0, 'Re':1, ref:'D'},'2.5':{'Ac':1, 'Re':2, ref:'F'},'4.0':{'Ac':1, 'Re':2},'6.5':{'Ac':2, 'Re':3},},
    'F':{batch_size:20, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':0, 'Re':1},
                       '1.0':{'Ac':0, 'Re':1, ref:'E'},'1.5':{'Ac':1, 'Re':2, ref:'G'},'2.5':{'Ac':1, 'Re':2},'4.0':{'Ac':2, 'Re':3},'6.5':{'Ac':3, 'Re':4},},
    'G':{batch_size:32, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':0, 'Re':1},'0.65':{'Ac':0, 'Re':1, ref:'F'},
                       '1.0':{'Ac':1, 'Re':2, ref:'H'},'1.5':{'Ac':1, 'Re':2},'2.5':{'Ac':2, 'Re':3},'4.0':{'Ac':3, 'Re':4},'6.5':{'Ac':5, 'Re':6},},
    'H':{batch_size:50, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':0, 'Re':1},'0.4':{'Ac':0, 'Re':1, ref:'G'},'0.65':{'Ac':1, 'Re':2, ref:'J'},
                       '1.0':{'Ac':1, 'Re':2},'1.5':{'Ac':2, 'Re':3},'2.5':{'Ac':3, 'Re':4},'4.0':{'Ac':5, 'Re':6},'6.5':{'Ac':7, 'Re':8},},
    'J':{batch_size:80, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':0, 'Re':1},'0.25':{'Ac':0, 'Re':1, ref:'H'},'0.4':{'Ac':1, 'Re':2, ref:'K'},'0.65':{'Ac':1, 'Re':2},
                       '1.0':{'Ac':2, 'Re':3},'1.5':{'Ac':3, 'Re':4},'2.5':{'Ac':5, 'Re':6},'4.0':{'Ac':7, 'Re':8},'6.5':{'Ac':10, 'Re':11},},
    'K':{batch_size:125, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':0, 'Re':1}, '0.15':{'Ac':0, 'Re':1, ref:'J'},'0.25':{'Ac':1, 'Re':2, ref:'L'},'0.4':{'Ac':1, 'Re':2},'0.65':{'Ac':2, 'Re':3},
                       '1.0':{'Ac':3, 'Re':4},'1.5':{'Ac':5, 'Re':6},'2.5':{'Ac':7, 'Re':8},'4.0':{'Ac':10, 'Re':11},'6.5':{'Ac':14, 'Re':15},},
    'L':{batch_size:200, '0.065':{'Ac':0, 'Re':1},'0.1':{'Ac':0, 'Re':1, ref:'K'}, '0.15':{'Ac':1, 'Re':2, ref:'M'},'0.25':{'Ac':1, 'Re':2},'0.4':{'Ac':2, 'Re':3},'0.65':{'Ac':3, 'Re':4},
                       '1.0':{'Ac':5, 'Re':6},'1.5':{'Ac':7, 'Re':8},'2.5':{'Ac':10, 'Re':11},'4.0':{'Ac':14, 'Re':15},'6.5':{'Ac':21, 'Re':22},},
    'M':{batch_size:315, '0.065':{'Ac':0, 'Re':1, ref:'L'},'0.1':{'Ac':1, 'Re':2, ref:'N'}, '0.15':{'Ac':1, 'Re':2},'0.25':{'Ac':2, 'Re':3},'0.4':{'Ac':3, 'Re':4},'0.65':{'Ac':5, 'Re':6},
                       '1.0':{'Ac':7, 'Re':8},'1.5':{'Ac':10, 'Re':11},'2.5':{'Ac':14, 'Re':15},'4.0':{'Ac':21, 'Re':22},'6.5':{'Ac':21, 'Re':22, ref:'L'},}, 
    'N':{batch_size:500, '0.065':{'Ac':1, 'Re':2, ref:'P'},'0.1':{'Ac':1, 'Re':2}, '0.15':{'Ac':2, 'Re':3},'0.25':{'Ac':3, 'Re':4},'0.4':{'Ac':5, 'Re':6},'0.65':{'Ac':7, 'Re':8},
                       '1.0':{'Ac':10, 'Re':11},'1.5':{'Ac':14, 'Re':15},'2.5':{'Ac':21, 'Re':22},'4.0':{'Ac':21, 'Re':22, ref:'M'},'6.5':{'Ac':21, 'Re':22, ref:'L'},},
    'P':{batch_size:800, '0.065':{'Ac':1, 'Re':2},'0.1':{'Ac':2, 'Re':3}, '0.15':{'Ac':3, 'Re':4},'0.25':{'Ac':5, 'Re':6},'0.4':{'Ac':7, 'Re':8},'0.65':{'Ac':10, 'Re':11},
                       '1.0':{'Ac':14, 'Re':15},'1.5':{'Ac':21, 'Re':22},'2.5':{'Ac':21, 'Re':22, ref:'N'},'4.0':{'Ac':21, 'Re':22, ref:'M'},'6.5':{'Ac':21, 'Re':22, ref:'L'},},
    'Q':{batch_size:1250, '0.065':{'Ac':2, 'Re':3},'0.1':{'Ac':3, 'Re':4}, '0.15':{'Ac':5, 'Re':6},'0.25':{'Ac':7, 'Re':8},'0.4':{'Ac':10, 'Re':11},'0.65':{'Ac':14, 'Re':15},
                       '1.0':{'Ac':21, 'Re':22},'1.5':{'Ac':21, 'Re':22, ref:'P'},'2.5':{'Ac':21, 'Re':22, ref:'N'},'4.0':{'Ac':21, 'Re':22, ref:'M'},'6.5':{'Ac':21, 'Re':22, ref:'L'},},
    'R':{batch_size:2000, '0.065':{'Ac':3, 'Re':4},'0.1':{'Ac':5, 'Re':6}, '0.15':{'Ac':7, 'Re':8},'0.25':{'Ac':10, 'Re':11},'0.4':{'Ac':14, 'Re':15},'0.65':{'Ac':21, 'Re':22},
                       '1.0':{'Ac':21, 'Re':22, ref:'Q'},'1.5':{'Ac':21, 'Re':22, ref:'P'},'2.5':{'Ac':21, 'Re':22, ref:'N'},'4.0':{'Ac':21, 'Re':22, ref:'M'},'6.5':{'Ac':21, 'Re':22, ref:'L'},},

}

export default Calc;