import  { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";  
import Calc from './Calc';
import Main from './Main';

function App() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [activeScreen, setActiveScreen] = useState('');

  const langu = ['ru', 'en', 'es']
    // This function put query that helps to
    // change the language

    useEffect(()=>{
      const url_parts = window.location.pathname.split('/');

      if(url_parts.length>2){
        setActiveScreen(url_parts[2]);
      }else{
        setActiveScreen('');
      }


//      setActiveScreen(url_parts[url_parts.length-1]);
//      console.log(url_parts);
      
    },[]);

 const ComponentTEST = () =>{
 // console.log(t);
  return(
    <>
      
      {t('description.part2')}
    </>
  )
 }
 const handleGoTo = (uri) => {
  setActiveScreen(uri);
  navigate(`/${i18n.language}/${uri}`);
}
  return (
    <>
      <div style={{width:`${(window.innerWidth>800)?800:window.innerWidth}px`, left:"50%", top:"0", position:"absolute",marginRight:"50%", transform:"translate(-50%, 0)"}}>

      <div style={{backgroundColor:"#ffffff", width:"100%", display:"inline-block"}}>
            <span style={{float:"right", alignItems:"center", display:"flex"}} >
            <img src={`/images/icon_${i18n.language}.png`} onClick={(e)=>{document.getElementById('languageSelectMenu').click()}}/>
                <select id="languageSelectMenu" name="lang" defaultValue={i18n.language} onChange={(e)=>{setActiveScreen(''); i18n.changeLanguage(e.target.value)}} style={{margin:"5px"}}>
                    {langu.map((lang, index)=>(
                      <option  key={index} value={lang} >
                       
                        {i18n.getDataByLanguage(lang).langName}
                      </option>
                    ))}

                </select>
            </span>
        </div>

        <div style={{height:"50px", width:`100%`, display:"flex", backgroundColor:"#aa44bb", alignItems:"center", borderRadius: "15px", justifyContent:"center"}}>

              <span style={{color:"#ddffdd", paddingLeft:"15px", paddingRight:"15px"}}>
               {t('description.calcTextHeader')}
              </span>

        </div>  

        <div style={{height:"50px", backgroundColor:"#fff", overflow:"hidden", overflowX:"auto", overflowY:"hidden", whiteSpace:"nowrap"}}>
            <span style={{...styleSpanMenu, background:`${(activeScreen==='')?'#aa44bb':'#D9D9D9'}`,color:`${(activeScreen==='calc2')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('')}}>{t('description.calcMain')} 
            </span>

            <span style={{...styleSpanMenu, background:`${(activeScreen==='calc')?'#aa44bb':'#D9D9D9'}`,color:`${(activeScreen==='calc1')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('calc')}}>{t('description.calcName')} 
            </span> 

            <span style={{...styleSpanMenu, background:`${(activeScreen==='pages')?'#D9D9D9':'#FFFFFF'}`,color:`${(activeScreen==='pages')?'#000000':'#979696'}`,
                         display:"none"}} 
                    onClick={()=>{handleGoTo('pages')}}>{t('description.calcName')} 
            </span>            
        </div>
<div style={{borderRadius: "15px",backgroundColor:"#aa44bb", width:"100%"}}>
    <Routes>
         <Route path={`/${i18n.language}/`}>
          <Route path="" element={ <Main /> } />
          <Route path='calc' element={<Calc />}>
            <Route path=":query" element={<Calc />} />
          </Route>
         <Route path='pages' element={<>Pages</>} />
         </Route>
         <Route path="*" element={ <Navigate replace to={`/${i18n.language}`} /> } />

    </Routes>
    </div>    

    <div>
    © 2024
    </div>
    </div>
    </>
  );
}

const styleSpanMenu = {
  //    background: "#771CBF", 
  //    color:"#FFFFFF",
  //    border: "1px solid #000000",
  //    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      cursor:"pointer",
      borderRadius: "15px",
      height:"30px",
      display:"inline-flex",
      justifyContent:"center",
      paddingLeft:"15px",
      paddingRight:"15px",
      margin:"10px",
      alignItems:"center"
  }

export default App;
