import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    detection: {
        order: ["path", "localStorage", "htmlTag", "subdomain"],
        lookupFromPathIndex: 0,
        checkWhitelist: true,
      },
    fallbackLng: 'en',
  //  initImmediate: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    preload: ["en", "ru", "es"],
  //  lng:'en',
    resources: {
      en: {
        langName:'English',
        translation: {
          description: {
            calcTextHeader:"AQL Calculator: Calculate Your Required Sample Size Quickly!",
            calcName:"AQL calculator",
            calcMain:"What is AQL?",
            batchsize:"Batch size",
            levelInspection:"Inspection level",
            levelGeneralInspection:"General inspection",
            levelSpecialInspection:"Special inspection",
            levelAQL:"AQL level",
            lotsize:"Sample size",
            acceptable:"Acceptable",
            discovered:"Discovered",
            result:"Result",
            critical:"Critical",
            major:"Significant",
            minor:"Minor",
            notfilledin:"Not filled in",
            passed:"Approved",
            failed:"Failed",
            mainText:{
              part1:"What is AQL?",
              part2:"AQL (Acceptable Quality Limit) - It is a statistical sampling technique that is used to identify the maximum number of defective items in a batch of products that can be accepted or rejected. AQL provides a baseline for quality control and helps businesses determine level of quality they can accept.",
              part3:"An example of defining AQL quality control.",
              part4: "For example, a monitor manufacturer may produce a lot of 5,000 units. The manufacturer may decide to use an AQL of 1.5% for the lot. This means that only 75 units per lot may be defective. For these conditions, a sample of 80 is required units. Units for inspection are selected randomly from the lot and inspected for defects. If the number of defective units is less than or equal to 3, then the lot is accepted. If the number of defective units is more than 3, then the entire lot is rejected.",
              part5:"How to use the calculator?",
              part6:"Using the calculator is very simple. First, you must enter the quantity of the production batch of goods in the field, as shown in the figure below:",
              part7:"Next from the list you need to select the required inspection level:",
              part8:"More details about inspection levels can be found below.",
              part9:"Inspection levels.",
              part10:"Level I - requires a smaller sample size. This level helps reduce inspection time, but increases the likelihood that defects will not be detected.",
              part11: "Level II is the most common level of inspection. This level covers a large amount of inspection without forcing buyers to go over budget.",
              part12:"Level III - This level uses a larger sample size, which significantly increases the time to complete the inspection, but increases the likelihood of detecting any defects.",
              part13:"General control levels represent the overall level of accuracy of the sampling method.",
              part14:"There are three levels of general control:",
              part15: "There are also special levels of inspection: S-1, S-2, S-3 and S-4. These are used in cases where only a very limited number of samples can be tested. Some of these scenarios may involve the buyer requiring inspection more packaging on just a few cartons, or labor-intensive inspections requiring disassembly or destruction of samples.",
              part16:"Next, you can set the AQL defect levels. By default, the standard parameters are set, which are used to check the quality of most consumer products.",
              part17:"AQL levels.",
              part18:"Critical - 0",
              part19:"Significant - 2.5",
              part20:"Minor - 4.0",
              part21:"But there is no universal rule regarding AQL requirements. Different manufacturers may have different requirements for AQL levels.",
              part22:"",
              part23:"",
              part24:"",            },
            part1: 'Edit <1>src/App.js</1> and save to reload.',
            part2: 'Learn React'
          }
        }
      },
      ru: {
        langName:'Русский',
        translation: {
          description: {
            calcTextHeader:"Калькулятор AQL: быстро рассчитайте необходимый размер выборки!",
            calcName:"AQL калькулятор",
            calcMain:"Что такое AQL?",
            batchsize:"Размер партии",
            levelInspection:"Уровень инспекции",
            levelGeneralInspection:"Общая инспекция",
            levelSpecialInspection:"Специальная инспекция",
            levelAQL:"Уровень AQL",
            lotsize:"Размер выборки",
            acceptable:"Допустимо",
            discovered:"Обнаружено",
            result:"Результат",
            critical:"Критический",
            major:"Значительный",
            minor:"Незначительный",
            notfilledin:"Не заполнено",
            passed:"Одобренный",
            failed:"Неуспешный",
            mainText:{
              part1:"Что такое AQL ?",
              part2:"AQL (Acceptable Quality Limit) -  Это метод статистической выборки, который используется для выявления максимального количества дефектных единиц в партии продуктов, которые могут быть приняты или отклонены. AQL обеспечивает базовую линию для контроля качества и помогает предприятиям определить уровень качества, который они могут принять.",
              part3:"Пример определения контроля качества AQL.",
              part4:"Например, производитель мониторов может производить партию из 5000 единиц. Производитель может принять решение использовать AQL в размере 1,5% для партии. Это означает, что только 75 единиц в партии могут быть дефектными. Для данных условий, требуется выборка из 80 единиц. Единицы для проверки выбираются случайным образом из партии и проверяются на наличие дефектов. Если количество дефектных единиц меньше или равно 3, то партия принимается. Если количество дефектных единиц больше 3, то вся партия отклоняется.",
              part5:"Как пользоваться калькулятором?",
              part6:"Использовать калькулятор очень просто. Для начала вы должны ввести количество выпускаемой партии товара в поле, как показано на рисунке ниже:",
              part7:"Далее из списка нужно выбрать необходимый уровень инспекции:",
              part8:"Подробнее об уровнях инспекции вы можете узнать ниже.",
              part9:"Уровни инспекции.",
              part10:"Уровень I - требует меньшего объема выборки. Этот уровень помогает сократить время проверки, но повышает вероятность того, что дефекты не будут обнаружены. ",
              part11:"Уровень II - самый распространенный уровень инспекции. Этот уровень охватывает большой объем инспекции и не заставляет покупателей выходить за рамки бюджета.",
              part12:"Уровень III – в этом уровне используется больший размер выборки, что значительно увеличивает время на проведение проверки, но повышает вероятность обнаружения любых дефектов.",
              part13:"Уровни общего контроля представляет собой общий уровень точности метода отбора проб.",
              part14:"Всего уровней общего контроля существует три:",
              part15:"Так же есть специальные уровни контроля: S-1, S-2, S-3 и S-4. Они используются в случаях, когда можно проверить только очень ограниченное количество образцов. Некоторые из этих сценариев могут включать требование покупателя о проверке большего количества упаковок только на нескольких картонных коробках, или трудоемкие проверки, требующие разборки или уничтожения образцов.",
              part16:"Далее можно установить уровни дефектов AQL. По умолчанию установлены стандартные параметры, которые используют для проверки качества большинства потребительских товаров.",
              part17:"Уровни AQL.",
              part18:"Критический - 0",
              part19:"Значительный - 2.5",
              part20:"Незначительный - 4.0",
              part21:"Но не существует универсального правила в отношении требований AQL. У разных производителей могут быть другие требования к уровням AQL.",
              part22:"",
              part23:"",
              part24:"",
            },
            part1: 'Русский',
            part2: 'Русский',
          }
        }
      },
      es: {
        langName:'Espan',
        translation: {
          description: {
            calcTextHeader:"Calculadora AQL: ¡Calcule rápidamente el tamaño de muestra requerido!",
            calcName:"Calculadora AQL (límite de calidad aceptable)",
            calcMain:"¿Qué es el AQL?",
            batchsize:"Tamaño del lote",
            levelInspection:"Nivel de inspección",
            levelGeneralInspection:"Inspección general",
            levelSpecialInspection:"Inspección especial",
            levelAQL:"Nivel AQL",
            lotsize:"Tamaño de la muestra",
            acceptable:"Aceptable",
            discovered:"Descubierto",
            result:"Resultado",
            critical:"Crítico",
            major:"Significativo",
            minor:"Menor",
            notfilledin:"No completado",
            passed:"Aprobado",
            failed:"Fracasado",
            mainText:{
              part1: "¿Qué es AQL?",
              part2: "AQL (límite de calidad aceptable) - Es una técnica de muestreo estadístico que se utiliza para identificar la cantidad máxima de artículos defectuosos en un lote de productos que pueden aceptarse o rechazarse. AQL proporciona una línea de base para control de calidad y ayuda a las empresas a determinar el nivel de calidad que pueden aceptar.",
              part3:"Un ejemplo de definición de control de calidad AQL.",
              part4: "Por ejemplo, un fabricante de monitores puede producir un lote de 5.000 unidades. El fabricante puede decidir utilizar un AQL del 1,5% para el lote. Esto significa que sólo 75 unidades por lote pueden ser defectuosas. Para estas condiciones, una muestra Se requieren 80 unidades. Las unidades para inspección se seleccionan al azar del lote y se inspeccionan en busca de defectos. Si el número de unidades defectuosas es menor o igual a 3, entonces se acepta el lote. Si el número de unidades defectuosas es mayor que 3 , entonces se rechaza todo el lote.",
              part5: "¿Cómo usar la calculadora?",
              part6:"Usar la calculadora es muy simple. Primero, debe ingresar la cantidad del lote de producción de bienes en el campo, como se muestra en la siguiente figura:",
              part7:"A continuación de la lista, debe seleccionar el nivel de inspección requerido:",
              part8:"Más detalles sobre los niveles de inspección se pueden encontrar a continuación.",
              part9: "Niveles de inspección.",
              part10:"Nivel I: requiere un tamaño de muestra más pequeño. Este nivel ayuda a reducir el tiempo de inspección, pero aumenta la probabilidad de que no se detecten defectos.",
              part11: "El nivel II es el nivel de inspección más común. Este nivel cubre una gran cantidad de inspecciones sin obligar a los compradores a excederse del presupuesto.",
              part12:"Nivel III: este nivel utiliza un tamaño de muestra más grande, lo que aumenta significativamente el tiempo para completar la inspección, pero aumenta la probabilidad de detectar cualquier defecto.",
              part13: "Los niveles de control generales representan el nivel general de precisión del método de muestreo.",
              part14: "Hay tres niveles de control general:",
              part15: "También existen niveles especiales de inspección: S-1, S-2, S-3 y S-4. Se utilizan en casos en los que sólo se puede analizar un número muy limitado de muestras. Algunos de estos escenarios pueden implicar el comprador requiere inspección de más embalajes en unas pocas cajas, o inspecciones que requieren mucha mano de obra y requieren desmontaje o destrucción de muestras.",
              part16:"A continuación, puede configurar los niveles de defectos AQL. De forma predeterminada, se configuran los parámetros estándar, que se utilizan para verificar la calidad de la mayoría de los productos de consumo.",
              part17: "Niveles AQL.",
              part18: "Crítico - 0",
              part19: "Significativo - 2,5",
              part20: "Menor - 4.0",
              part21:"Pero no existe una regla universal con respecto a los requisitos de AQL. Diferentes fabricantes pueden tener diferentes requisitos para los niveles de AQL.",
              part22:"",
              part23: "",
              part24:"",            },
            part1: 'Espan',
            part2: 'OLA OLA OLA'
          }
        }
      }      
    }
  });

export default i18n;