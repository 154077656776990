import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Main = () => {
    const { t } = useTranslation();


    useEffect(()=>{
        let scriptEle = document.getElementById("faq_snippet_g");

        if(scriptEle === null || scriptEle === undefined){
            scriptEle = document.createElement("script");
        }
      //  console.log(scriptEle);
        scriptEle.type = "application/ld+json";
        scriptEle.id = "faq_snippet_g";
        scriptEle.text =     JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": `${t('description.mainText.part1')}`,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": `${t('description.mainText.part2')}`
              }
            }]
          });
           //`console.log('${t('description.mainText.part3')}')`;
        document.head.appendChild(scriptEle);
    }, []);

    return(
        <>
        <div style={{paddingLeft:"15px", paddingRight:"15px"}}>


            <p style={{textIndent:"25px", padding:"5px", textAlign:"center"}}>
              <strong> {t('description.mainText.part1')}</strong>
            </p>

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part2')}
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"center"}}>
            <strong> {t('description.mainText.part3')}</strong>
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part4')}
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"center"}}>
             <strong> {t('description.mainText.part5')}</strong>
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part6')}
            </p> 

            <img src="/images/1.png" style={{width:"100%"}}/>

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part7')}
            </p> 

            <img src="/images/2.png" style={{width:"50%"}}/>

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part8')}
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"center"}}>
             <strong> {t('description.mainText.part9')}</strong>
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part13')}
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              <strong>{t('description.mainText.part14')}</strong>
            </p> 
<ul style={{ paddingLeft:"35px", paddingRight:"35px", paddingBottom:"25px"}}>
    <li style={{marginBottom:"15px"}}>{t('description.mainText.part10')}</li>
    <li style={{marginBottom:"15px"}}>{t('description.mainText.part11')}</li>
    <li style={{marginBottom:"15px"}}>{t('description.mainText.part12')}</li>
</ul>

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part15')}
            </p> 

            <p style={{textIndent:"25px", padding:"5px", textAlign:"center"}}>
             <strong> {t('description.mainText.part17')}</strong>
            </p> 



            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part16')}
            </p> 

            <ul style={{ paddingLeft:"35px", paddingRight:"35px", paddingBottom:"25px"}}>
                 <li style={{marginBottom:"15px"}}>{t('description.mainText.part18')}</li>
                 <li style={{marginBottom:"15px"}}>{t('description.mainText.part19')}</li>
                 <li style={{marginBottom:"15px"}}>{t('description.mainText.part20')}</li>
            </ul>

            <img src="/images/3.png" style={{width:"50%"}}/>

            <p style={{textIndent:"25px", padding:"5px", textAlign:"justify"}}>
              {t('description.mainText.part21')}
            </p> 

            <p style={{paddingBottom:"30px"}}>

            </p>
            

            <p style={{paddingBottom:"0px", textAlign:"center"}}>
                
            </p>
</div>

        </>
    )
}

export default Main;